import { cloneDeepObject } from '@/utils/common';
import useEditorStore from '../stores/editor';
import useSectionStore from '../stores/section';

import type { ScreenType } from '@/types/custom';
import type { PageSection } from '@/types/graphql';
import {
  actionComponentForceUpdateConfigurations,
  actionComponentUpdateSetting,
  actionCreateComponent,
  actionDeleteComponent,
  actionDeleteLayoutColumn,
} from '../../history/use-cases/action';
import { historyCreate } from '../../history/use-cases/history';
import {
  builderAddComponent,
  builderDeleteComponent,
  builderForceUpdateComponents,
  builderUpdateComponent,
} from '../../preview/utils/builder';
import {
  convertComponentToJSON,
  convertComponentToString,
  generateNewUid,
  getComponentByUid,
  getIndexFromParent,
  getParentComponentByUid,
  removeComponentByUid,
  removeLayoutColByUid,
  updateComponentUsingCommand,
  updateWidgetTypeSettingComponent,
} from '../utils/section/component';
import type { ActionOptions, Component, ComponentCommand, GroupTypeSetting, Section } from '../utils/types';
import {
  cacheAddComponentBySection,
  cacheDeleteComponentBySectionCid,
  cacheGetSectionCidByComponentUid,
} from './cache';
import { event } from './event';
import { sectionUpdateComponent } from './section';
import { sentryCaptureException } from './sentry';

export type ActionSettingInput = {
  groupType: GroupTypeSetting;
  componentUid: string;
  controlType: string;
  controlId: string;
  newValue: any;
  screenId: ScreenType;
  hasDevices?: boolean;
};

export const componentCreate = ({
  cid,
  uids,
  newComponent,
  newComponents,
  parentData,
  options,
}: {
  cid: string;
  uids: string[];
  newComponent: string; // component of section
  newComponents: Component[];
  parentData: { toParent: string; toIndex: number };
  options?: ActionOptions;
}) => {
  const sectionStore = useSectionStore();

  // History
  if (!options?.noRecordHistory) {
    historyCreate(actionCreateComponent({ cid, uids, newComponent, newComponents, parentData }));
  }

  const section = sectionStore.getItemByCid(cid);
  if (section?.cid) {
    const clone = cloneDeepObject(section) as PageSection;
    const { jsonComponent, updateComponents } = updateWidgetTypeSettingComponent(JSON.parse(newComponent ?? '{}'));
    let newUpdatedComponents = newComponents;
    if (updateComponents.length) {
      newUpdatedComponents = newComponents.map((item) => {
        const component = getComponentByUid(jsonComponent, item.uid);
        return component ?? item;
      });
    }
    clone.component = JSON.stringify(jsonComponent);
    sectionStore.update(clone);

    // Update preview iframe
    if (!options?.noApplyToPreview) {
      newUpdatedComponents.forEach((element: Component, index: number) => {
        builderAddComponent(element, parentData.toParent, parentData.toIndex + index);
      });
    }

    cacheAddComponentBySection(clone);
  }
};

export const componentDelete = (uids: string[], options?: ActionOptions) => {
  const sectionStore = useSectionStore();
  const editorStore = useEditorStore();

  const sectionCid = cacheGetSectionCidByComponentUid(uids[0]);
  const section = sectionStore.getItemByCid(sectionCid);
  if (section?.cid) {
    const clone = cloneDeepObject(section) as Section;

    // History
    if (!options?.noRecordHistory) {
      if (!section?.component) {
        sentryCaptureException('componentDelete', 'Component not found', section, {
          level: 'info',
        });
      }
      const jsonComponent = convertComponentToJSON(section.component || '');

      if (jsonComponent) {
        const parent = getParentComponentByUid(jsonComponent, uids[0]);
        const parentData: { toParent: string; toIndex: number } = {
          toParent: '',
          toIndex: 0,
        };
        const oldComponents: Component[] = [];
        if (!parent) {
          sentryCaptureException(
            'componentDelete',
            'Parent not found',
            {
              jsonComponent,
              uids,
            },
            {
              level: 'info',
            },
          );
        }
        if (parent) {
          uids.forEach((uid) => {
            const component = getComponentByUid(jsonComponent, uid);
            if (component) {
              oldComponents.push(component);
            }
          });

          parentData.toParent = parent?.uid || '';
          parentData.toIndex = getIndexFromParent(parent, uids[0]);
          historyCreate(
            actionDeleteComponent({
              cid: section?.cid,
              uids,
              oldComponent: section?.component || '{}',
              oldComponents,
              parentData,
            }),
          );
        }
      }
    }

    let newComponent = clone.component;
    uids.forEach((uid) => {
      if (newComponent) {
        newComponent = removeComponentByUid(newComponent, uid);
      }
    });

    clone.component = newComponent;
    sectionStore.update(clone);
    const cloneSection = JSON.parse(JSON.stringify(clone));

    // Update preview iframe
    if (!options?.noApplyToPreview) {
      uids.forEach((uid) => {
        builderDeleteComponent(uid);
      });
    }

    // Close sidebar setting if delete editing component
    const editingComponentUid = editorStore.getEditingComponentUid;
    if (editingComponentUid) {
      const exitUid = uids.some((uid) => uid == editingComponentUid);
      if (exitUid) {
        editorStore.setEditingComponentUid(null);
      }

      const editingSectionCid = cacheGetSectionCidByComponentUid(editingComponentUid);
      if (sectionCid == editingSectionCid) {
        const jsonComponent = convertComponentToJSON(newComponent || '');
        if (jsonComponent) {
          // Check delete parent editing component
          const isExitsComponent = getComponentByUid(jsonComponent, editingComponentUid);
          if (!isExitsComponent) {
            editorStore.setEditingComponentUid(null);
          }
        }
      }
    }

    // Update cache
    cacheDeleteComponentBySectionCid(cloneSection.cid);
    cacheAddComponentBySection(cloneSection);
  }
};

export const componentRemove = (uids: string[], options?: ActionOptions) => {
  const sectionStore = useSectionStore();

  const sectionCid = cacheGetSectionCidByComponentUid(uids[0]);
  const section = sectionStore.getItemByCid(sectionCid);
  if (!section?.cid) return;

  const oldComponent = section?.component;
  if (!oldComponent) return;

  const deleteCommands: ComponentCommand[] = [];
  for (const index in uids) {
    deleteCommands.push({ type: 'remove_component', componentUid: uids[index] });
  }
  const updateComponent = updateComponentUsingCommand({
    component: oldComponent,
    commands: deleteCommands,
  });
  const newComponent = updateComponent?.component;
  if (newComponent) {
    sectionUpdateComponent({
      cid: sectionCid,
      newComponent: newComponent,
      options,
    });
  }
  // Update cache
  cacheDeleteComponentBySectionCid(sectionCid);
  cacheAddComponentBySection(section);
};

export const componentLayoutDelete = (uids: string[], options?: ActionOptions) => {
  const sectionStore = useSectionStore();

  const sectionCid = cacheGetSectionCidByComponentUid(uids[0]);
  const section = sectionStore.getItemByCid(sectionCid);
  if (section?.cid) {
    const clone = cloneDeepObject(section) as Section;

    // Get parentUid
    let parentUid: string = '';
    const jsonComponent = convertComponentToJSON(clone.component || '');
    if (jsonComponent) {
      const parent = getParentComponentByUid(jsonComponent, uids[0]);
      if (parent?.uid) {
        parentUid = parent?.uid;
      }
    }

    let newComponent = clone.component;
    uids.forEach((uid) => {
      if (newComponent) {
        newComponent = removeLayoutColByUid(newComponent, uid);
      }
    });

    // History
    if (!options?.noRecordHistory) {
      if (!section?.component) {
        sentryCaptureException('componentLayoutDelete', 'Component not found', section, {
          level: 'info',
        });
      }
      const jsonComponent = convertComponentToJSON(clone.component || '');
      const jsonComponentNew = convertComponentToJSON(newComponent || '');
      if (jsonComponent && jsonComponentNew) {
        const parent = getComponentByUid(jsonComponent, parentUid);
        const parentData: { toParent: string; toIndex: number } = {
          toParent: '',
          toIndex: 0,
        };
        const oldComponents: Component[] = [];
        if (!parent) {
          sentryCaptureException(
            'componentLayoutDelete',
            'Parent not found',
            {
              jsonComponent,
              uids,
            },
            {
              level: 'info',
            },
          );
        }
        if (parent) {
          const parentNew = getComponentByUid(jsonComponentNew, parentUid);
          if (parentNew) {
            const oldLayoutSetting = parent?.settings?.layout;
            const newLayoutSetting = parentNew?.settings?.layout;
            uids.forEach((uid) => {
              const component = getComponentByUid(jsonComponent, uid);
              if (component) {
                oldComponents.push(component);
              }
            });

            parentData.toParent = parent?.uid || '';
            parentData.toIndex = getIndexFromParent(parent, uids[0]);
            historyCreate(
              actionDeleteLayoutColumn({
                cid: section?.cid,
                uids,
                rowUid: parent.uid || '',
                rowSettingLayoutOld: oldLayoutSetting,
                rowSettingLayoutNew: newLayoutSetting,
                oldComponent: section?.component || '{}',
                oldComponents,
                parentData,
              }),
            );
          }
        }
      }
    }

    clone.component = newComponent;
    sectionStore.update(clone);
    const cloneSection = JSON.parse(JSON.stringify(clone));

    // Update preview iframe
    if (!options?.noApplyToPreview) {
      const jsonComponent = convertComponentToJSON(cloneSection.component || '');
      if (jsonComponent) {
        const parent = getComponentByUid(jsonComponent, parentUid);
        if (parent?.uid) {
          uids.forEach((uid) => {
            builderUpdateComponent({
              uid: parent.uid,
              propName: 'layout',
              propValue: cloneDeepObject(parent?.settings?.layout),
              group: 'setting',
            });
            builderDeleteComponent(uid);
          });
        }
      }
    }

    // Update cache
    cacheDeleteComponentBySectionCid(cloneSection.cid);
    cacheAddComponentBySection(cloneSection);
  }
};

export const componentDuplicate = (uid: string, options?: ActionOptions) => {
  //  define store
  const sectionStore = useSectionStore();

  //get section have component need to update
  const sectionCid = cacheGetSectionCidByComponentUid(uid);
  const section = sectionStore.getItemByCid(sectionCid);
  const cloneSection = cloneDeepObject(section) as PageSection;
  const jsonComponent = convertComponentToJSON(cloneSection?.component ?? '');
  if (jsonComponent) {
    // get parents component of current component to add after current component
    const parent = getParentComponentByUid(jsonComponent, uid);
    let componentReturn = '';
    if (parent) {
      if (options?.type === 'layout') {
        // duplicate layout
        const newCol = (parent?.childrens?.length ?? 0) + 1;
        parent.settings = {
          ...parent.settings,
          layout: {
            desktop: {
              ...parent.settings?.layout?.desktop,
              cols: Array.from({ length: newCol }, () => 12 / newCol),
            },
          },
        };

        const childrens = parent?.childrens ?? [];
        childrens.forEach((child, index) => {
          if (child.uid === uid) {
            const childClone = cloneDeepObject(child);
            generateNewUid(childClone);

            // add new component after current component
            childrens.splice(index + 1, 0, childClone);
          }
        });
      } else {
        const childrens = parent?.childrens ?? [];
        let componentClone: Component | undefined;
        childrens.forEach((child, index) => {
          if (child.uid === uid) {
            const childClone = cloneDeepObject(child);
            generateNewUid(childClone);

            // add new component after current component
            childrens.splice(index + 1, 0, childClone);
            componentClone = childClone;
          }
        });
        componentReturn = componentClone?.uid ?? '';
      }

      sectionUpdateComponent({
        cid: sectionCid,
        newComponent: convertComponentToString(jsonComponent),
        options,
      });

      return componentReturn;
    }
  }
};

export const componentLayoutDuplicate = (uid: string, options?: ActionOptions) => {
  //  define store
  const sectionStore = useSectionStore();

  //get section have component need to update
  const sectionCid = cacheGetSectionCidByComponentUid(uid);
  const section = sectionStore.getItemByCid(sectionCid);
  const cloneSection = cloneDeepObject(section) as PageSection;
  const jsonComponent = convertComponentToJSON(cloneSection?.component ?? '');
  if (jsonComponent) {
    // get parents component of current component to add after current component
    const parent = getParentComponentByUid(jsonComponent, uid);
    if (parent) {
      const newCol = (parent?.childrens?.length ?? 0) + 1;
      parent.settings = {
        ...parent.settings,
        layout: {
          desktop: {
            ...parent.settings?.layout?.desktop,
            cols: Array.from({ length: newCol }, () => 12 / newCol),
          },
        },
      };

      const childrens = parent?.childrens ?? [];
      childrens.forEach((child, index) => {
        if (child.uid === uid) {
          const childClone = cloneDeepObject(child);
          generateNewUid(childClone);

          // add new component after current component
          childrens.splice(index + 1, 0, childClone);
        }
      });

      sectionUpdateComponent({
        cid: sectionCid,
        newComponent: convertComponentToString(jsonComponent),
        options,
      });

      // Update cache
      cacheAddComponentBySection(cloneSection);
    }
  }
};

type ComponentUpdateSettingInput = {
  cid: string;
  component: string;
  settings: ActionSettingInput[];
  options?: ActionOptions;
  customizeOldData?: string;
};
export const componentUpdateSetting = ({
  cid,
  component,
  settings,
  options,
  customizeOldData,
}: ComponentUpdateSettingInput) => {
  const sectionStore = useSectionStore();
  const editorStore = useEditorStore();
  const currentEditingUid = editorStore.getEditingComponentUid;
  const section = sectionStore.getItemByCid(cid);
  if (section) {
    const oldComponent = customizeOldData || section?.component || '{}';
    // History
    if (!options?.noRecordHistory) {
      historyCreate(
        actionComponentUpdateSetting({
          cid,
          oldComponent,
          newComponent: component,
          settings,
        }),
      );
    }

    const clone = cloneDeepObject(section);
    clone.component = component;
    sectionStore.update(clone);
    // Handle preview
    if (settings?.length) {
      settings.forEach((setting) => {
        // Apply to preview
        if (!options?.noApplyToPreview) {
          if (setting.controlId && setting.componentUid) {
            const jsonComponent = convertComponentToJSON(component);
            if (jsonComponent) {
              const currentComponent = getComponentByUid(jsonComponent, setting.componentUid);
              const data: Record<GroupTypeSetting, any> = {
                advanced: currentComponent?.advanced,
                setting: currentComponent?.settings,
                style: currentComponent?.styles,
              };
              builderUpdateComponent({
                uid: setting.componentUid,
                propName: setting.controlId,
                propValue: data?.[setting.groupType]?.[setting.controlId],
                group: setting.groupType,
              });
              event.emit('component-update-setting', { uid: setting.componentUid, controlId: setting.controlId });
            }
          }
        }
        // update cache if control type = childrens, child-item, grid
        if (setting.componentUid) {
          if (
            setting.controlType === 'childrens' ||
            (['grid', 'layout', 'layout-banner'].includes(setting.controlType) && setting.screenId == 'desktop') ||
            setting.controlType === 'child-item' ||
            setting.controlType === 'product-bundle-child-item'
          ) {
            cacheAddComponentBySection(clone);

            // Apply to preview
            if (!options?.noApplyToPreview) {
              const jsonComponentOld = convertComponentToJSON(oldComponent);
              if (jsonComponentOld) {
                const cacheComponent = getComponentByUid(jsonComponentOld, setting.componentUid);
                if (cacheComponent && cacheComponent.settings && cacheComponent.childrens?.length) {
                  for (let i = 0; i < cacheComponent.childrens.length; i++) {
                    const child = cacheComponent.childrens[i];
                    builderDeleteComponent(child.uid);
                  }
                }
              }

              const jsonComponent = convertComponentToJSON(component);
              if (jsonComponent) {
                const currentComponent = getComponentByUid(jsonComponent, setting.componentUid);
                if (currentComponent && currentComponent.settings && currentComponent.childrens?.length) {
                  for (let i = 0; i < currentComponent.childrens.length; i++) {
                    const child = currentComponent.childrens[i];
                    builderAddComponent(child, currentComponent.uid, i);
                  }
                }
              }
            }
          }
        }

        // Update event spacing
        if (setting.controlId == 'spacing-setting') {
          event.emit('on-change-spacing', {
            spacing: null,
          });
        }
      });
    }
  }
};

export const componentUpdateMetaData = (
  sectionUId: string,
  componentUId: string,
  metaKey: keyof Pick<Component, 'customLabel'>,
  newValue: any,
) => {
  const sectionStore = useSectionStore();
  const section = sectionStore.getItemByCid(sectionUId);
  const clone = cloneDeepObject(section);
  if (!clone || typeof clone.component !== 'string') {
    return;
  }

  const jsonComponent = JSON.parse(clone.component);
  const targetComponent = getComponentByUid(jsonComponent, componentUId);
  if (!targetComponent) {
    return;
  }

  if (metaKey in targetComponent) {
    targetComponent[metaKey] = newValue;
    clone.component = JSON.stringify(jsonComponent);
    sectionStore.update(clone);
  }
};

type ComponentForceUpdateConfigurationInput = {
  cid: string;
  component: string;
  settings: {
    componentUid: string;
  };
  options?: ActionOptions;
};
export const componentForceUpdateConfigurations = ({
  cid,
  component,
  settings,
  options,
}: ComponentForceUpdateConfigurationInput) => {
  const sectionStore = useSectionStore();

  const section = sectionStore.getItemByCid(cid);
  if (section) {
    const oldComponent = section?.component || '{}';
    // History
    if (!options?.noRecordHistory) {
      historyCreate(
        actionComponentForceUpdateConfigurations({
          cid,
          oldComponent,
          newComponent: component,
          settings,
        }),
      );
    }

    const clone = cloneDeepObject(section);
    clone.component = component;
    sectionStore.update(clone);

    const jsonComponent = convertComponentToJSON(component);
    if (jsonComponent) {
      const currentComponent = getComponentByUid(jsonComponent, settings.componentUid);
      const data: Record<GroupTypeSetting, any> = {
        style: currentComponent?.styles,
        advanced: currentComponent?.advanced,
        setting: currentComponent?.settings,
      };
      const componentGroups: GroupTypeSetting[] = ['style', 'advanced', 'setting'];
      for (const group of componentGroups) {
        builderForceUpdateComponents({
          uid: settings.componentUid,
          data: data[group],
          group: group,
        });
      }
    }
  }
};
